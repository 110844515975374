<template>
  <div class="food-wrapper">
    <button class="food"></button>
  </div>
</template>

<script>
import { spriteImagePaths } from '@/sprites'

export default {
  data: function () {
    return {
      moodSpritePath: '',
      moodClass: ''
    }
  },
  name: 'Food',
  methods: {
    addEventListeners () {
      const foodElement = document.querySelector('.food')
      const eevee = document.querySelector('.eevee')

      function preventBehavior (e) {
        e.preventDefault()
      }

      document.addEventListener('touchmove', preventBehavior, { passive: false })

      foodElement.addEventListener('touchstart', (event) => {
        const target = event.target

        target.classList.add('dragging')
        this.setFoodToMousePosition(event, target)

        window.addEventListener('touchmove', (event) => {
          if (target.classList.contains('dragging')) {
            this.setFoodToMousePosition(event, target)

            if (this.$store.state.isNight) {
              this.setEeveeMood('sleepyHalf')
            } else {
              this.setEeveeMood('hungry')
            }
          }
        })
      })

      foodElement.addEventListener('touchend', (event) => {
        const target = event.target
        const eTopX = eevee.getBoundingClientRect().left
        const eBotX = eTopX + eevee.offsetWidth
        const fTopY = foodElement.getBoundingClientRect().top
        const foodOverlaps = fTopY <= eBotX

        target.classList.remove('dragging')
        target.style.left = '50%'
        target.style.top = '50%'

        if (foodOverlaps) {
          if (this.$store.state.isNight) {
            this.setEeveeMood('sleepyEating')

            window.setTimeout(() => {
              this.setEeveeMood('sleepy')
            }, 2500)
          } else {
            this.setEeveeMood('eating')

            window.setTimeout(() => {
              this.setEeveeMood('normal')
            }, 2500)
          }
        } else {
          if (this.$store.state.isNight) {
            this.setEeveeMood('sleepySad')

            window.setTimeout(() => {
              this.setEeveeMood('sleepy')
            }, 2500)
          } else {
            this.setEeveeMood('sad')

            window.setTimeout(() => {
              this.setEeveeMood('normal')
            }, 2500)
          }
        }

        window.removeEventListener('touchmove', (event) => {
          this.setFoodToMousePosition(event, target)

          if (this.$store.state.isNight) {
            this.setEeveeMood('sleepyHalf')
          } else {
            this.setEeveeMood('hungry')
          }
        })
      })
    },
    setFoodToMousePosition (event, target) {
      target.style.left = event.changedTouches[0].pageX + 'px'
      target.style.top = event.changedTouches[0].pageY + 'px'
    },
    setEeveeMood (mood) {
      this.$store.commit('setMoodSpritePath', spriteImagePaths[mood])
      this.$store.commit('setMoodClass', mood)
    }
  },
  mounted: function () {
    this.addEventListeners()
  }
}
</script>

<style lang="scss" scoped>
.food-wrapper {
  width: 100px;
  height: 100px;
  margin: auto;
  position: relative;

  .food {
    animation: updown 2s infinite;
    width: 100px;
    height: 100px;
    display: block;
    background-color: transparent;
    background-image: url('/img/evoli-food.png');
    background-size: 100% 100%;
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.dragging {
      animation: none;
      position: fixed;
      transform: translate(-50%, -50%);
    }
  }
}

@keyframes updown {
  50% {transform: translate(-50%, calc(-50% + 10px))}
}
</style>
