<template>
  <div class="bottom-elements">
    <Food />
  </div>
</template>

<script>
import Food from '../components/Food.vue'

export default {
  name: 'App',
  components: {
    Food
  }
}
</script>
